import fetch from "@/utils/service";

export function getMoreImg(params) {
  return fetch({
    url: "/v1.0/api/more_img/getMoreImg",
    params: params,
  });
}
export function getDynamic(params) {
    return fetch({
      url: "/v1.0/api/village/all_current_events",
      params: params,
    });
  }
export function personnel(params) {
    return fetch({
      url: "/v1.0/api/village_talent/get_page_list",
      params: params,
    });
  }