<template>
  <dv-full-screen-container class="bg">
    <!-- 顶部 -->
    <Header
      pageNum="3/7"
      :villageName="detail.village_name"
      :slogan="detail.slogan"
      :curCountDown="curCountDown"
      @onShowNext="onShowNext"
    />
    <!-- 内容 -->
    <div class="main content">
      <div class="meitu">
        <div class="main__left-title left">美图 TOP10</div>
        <vue-seamless-scroll
          :data="moreImgList"
          class="seamless-warp"
          :class-option="classOption"
        >
          <div
            class="main__left-list"
            v-for="(item, index) in moreImgList"
            :key="index"
          >
            <div class="main__left-list_item f">
              <div class="index f-ai-c fz-14">{{ index + 1 }}</div>
              <div class="itemImage">
                <img :src="item.img_url" alt />
              </div>
              <div class="f-1 ml-17 f-d-c f-j-c">
                <div class="fz-20 fw-b">{{ item.img_name }}</div>
                <div class="mt-17 fz-16 f-j-sb f-ai-c">
                  拍摄人:{{ item.author }}
                  <div class="fz-16 f-ai-c">
                    <img class="icon mr-5" src="../assets/imgs/zan.png" alt />
                    {{ item.support_count }}
                  </div>
                </div>
                <!-- moment(item.shoot_dateshootDate).format('yyyy年MM月dd日') -->
                <div class="mt-17 fz-16">
                  拍摄日期: {{ item.shoot_dateshootDate }}
                </div>
              </div>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
      <div class="middleBox">
        <div class="twoCommittees">
          <div class="main__middle-up-title left">两委发布</div>
          <div class="main__middle-list f-fw">
            <div
              class="main__middle-list_item"
              v-for="(item, index) in twoCommitteesList"
              :key="index"
            >
              <div class="f">
                <div class="itemImage">
                  <img :src="item.imgs[0]" alt />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">{{ item.title }}</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/view.png" alt />
                  {{ item.view_count }}
                </div>
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/zan.png" alt />
                  {{ item.support_count }}
                </div>
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/share.png" alt />
                  {{ item.share_count }}
                </div>
              </div>
            </div>
            <!-- <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/view.png" alt />169
                </div>
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/zan.png" alt />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt
                  />165
                </div>
              </div>
            </div>
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/view.png" alt />169
                </div>
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/zan.png" alt />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt
                  />165
                </div>
              </div>
            </div>
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/view.png" alt />169
                </div>
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/zan.png" alt />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt
                  />165
                </div>
              </div>
            </div>-->
          </div>
        </div>
        <div class="transaction">
          <div class="main__middle-up-title left">交易广场</div>
          <div class="main__middle-list f-fw">
            <div
              class="main__middle-list_item"
              v-for="(item, index) in marketList"
              :key="index"
            >
              <div class="f">
                <div class="itemImage">
                  <img :src="item.imgs[0]" alt />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">{{ item.title }}</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/view.png" alt />
                  {{ item.view_count }}
                </div>
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/zan.png" alt />
                  {{ item.support_count }}
                </div>
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/share.png" alt />
                  {{ item.share_count }}
                </div>
              </div>
            </div>
            <!-- <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/view.png" alt />169
                </div>
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/zan.png" alt />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt
                  />165
                </div>
              </div>
            </div>
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/view.png" alt />169
                </div>
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/zan.png" alt />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt
                  />165
                </div>
              </div>
            </div>
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/view.png" alt />169
                </div>
                <div class="f-ai-c fz-16">
                  <img class="icon mr-5" src="../assets/imgs/zan.png" alt />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt
                  />165
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div class="work">
        <div class="main__right-title left">务工人才 TOP18</div>
        <div class="main__left-list">
          <div
            class="main__left-list_item f"
            v-for="(item, index) in personnelList"
            :key="index"
          >
            <div class="index01 f-ai-c fz-14">{{ index + 1 }}</div>
            <div class="itemImage01">
              <img :src="item.avatar" alt />
            </div>
            <div class="f-1 ml-17 f-d-c f-j-sb">
              <div>
                <div class="fz-20 fw-b">{{ item.talent_name }}</div>
                <div class="mt-17 fz-16">职位：{{ item.profession }}</div>
              </div>
              <div class="fz-16 f-ai-c">
                <img class="icon mr-5" src="../assets/imgs/zan.png" alt />
                {{ item.support_count }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="code f-ai-c">
        <div class="f-1">
          <div class="txt f-ai-c">
            <img class="view" src="../assets/industry/查看@2x.png" alt />
            {{ scanConfig.follow_count }}人
          </div>
          <div class="txt f-ai-c mt-10">
            <img class="click" src="../assets/industry/点击@2x.png" alt />
            {{ scanConfig.visit_count || 0 }}次
          </div>
        </div>
        <img class="icon" :src="scanConfig.qrcode_img" alt />
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { getConfig } from "@/api/village";
import { getHomepage } from "@/api/survey/index";
import { getMoreImg, getDynamic, personnel } from "@/api/dynamic/index";
import Header from "./components/Header";
import moment from "moment";
export default {
  created() {
    this.getMoreImg();
    this.twoCommittees();
    this.market();
    this.personnel();
  },
  components: {
    vueSeamlessScroll,
    Header,
  },
  computed: {
    classOption() {
      return {
        curCountDown: 60,
        interval: null,
        id: "",
        config: {},
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      villageId: "",
      moreImgList: [],
      twoCommitteesList: [],
      marketList: [],
      personnelList: [],
      detail: {},
      defaultDetail: {
        slogan: "百川汇海 胜在齐心",
        village_name: "百胜",
      },
      curCountDown: 60,
      interval: null,
      id: this.$route.query.village_id,
      config: {},
      scanConfig: {
        visit_count: 6,
        qrcode_img:
          "https://r.517lc.cn/Upload/WxQrCode/202011200030452461/73b9710771eb45a9886f7da242b8117d.png",
        countdown: 60,
      },
    };
  },
  mounted() {
    const id = this.$route.query.village_id || "6";
    this.id = id;
    this.getConfig(id);
    this.getHomepage(id);
  },
  methods: {
    onShowNext() {
      clearInterval(this.interval);
      this.$router.push({
        path: "industry",
        query: { village_id: this.id },
      });
    },
    async getConfig(id) {
      const { data } = await getConfig({
        village_id: id,
      });
      if (data.code === "200") {
        this.scanConfig = data.data;
        this.setTimer(data.data.countdown);
      }

      console.log("data", data);
    },
    setTimer(time) {
      let that = this;
      that.curCountDown = time || 60;
      this.interval = setInterval(function () {
        that.curCountDown = that.curCountDown - 1;
        if (that.curCountDown <= 0) {
          clearInterval(that.interval);
          that.$router.push({
            path: "industry",
            query: { village_id: that.id },
          });
        }
      }, 1000);
    },
    getMoreImg() {
      getMoreImg({
        village_id: this.id || "6",
        page: 1,
        rows: 10,
      }).then((res) => {
        this.moreImgList = res.data.data.pagedata;
      });
    },
    twoCommittees() {
      getDynamic({
        page: 1,
        rows: 4,
        event_type: 1,
      }).then((res) => {
        this.twoCommitteesList = res.data.data.pagedata;
      });
    },
    market() {
      getDynamic({
        page: 1,
        rows: 4,
        event_type: 2,
      }).then((res) => {
        this.marketList = res.data.data.pagedata;
      });
    },
    personnel() {
      personnel({
        village_id: this.id || 6,
        page: 1,
        rows: 5,
      }).then((res) => {
        console.log("res", res);
        this.personnelList = res.data.data.pagedata;
      });
    },
    getHomepage(id) {
      getHomepage({ village_id: id }).then((res) => {
        console.log(res);
        this.detail = res.data.data || this.defaultDetail;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: #040b13;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: h(100);
  width: 100%;
  background: url("../assets/common/大标题_底板@2x.png") no-repeat;
  background-size: 100% 100%;
  color: #56fefe;
  font-size: 24px;
  .textLeft,
  .textRight {
    margin-bottom: h(27);
  }
  .textLeft {
    margin-left: 100px;
  }
  .textRight {
    display: flex;
    margin-right: 20px;
    .pageNum {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      width: 96px;
      height: h(40);
      line-height: h(40);
      background: #0c2b46;
      border-radius: 20px;
      border: 1px solid #01f9ed;
    }
  }
  .textCenter {
    margin-bottom: h(10);
    display: flex;
    font-size: 34px;
    .name {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      line-height: h(53);
      font-size: 32px;
      font-weight: 700;
      width: 126px;
      height: h(53);
      background: url("../assets/common/选中@2x.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.content {
  display: flex;
  padding: h(24);
  color: #ffffff;
  .meitu {
    width: 515px;
    height: h(928);
    background: url("../assets/dynamic/美图外框@2x.png") no-repeat;
    background-size: 100% 100%;
    padding: h(20) 25px;
  }
  .middleBox {
    margin-left: 18px;
    .twoCommittees,
    .transaction {
      width: 956px;
      height: h(455);
      background: url("../assets/dynamic/两委发布外框@2x.png") no-repeat;
      background-size: 100% 100%;
      padding: h(20);
    }
    .transaction {
      margin-top: h(18);
    }
  }
  .work {
    margin-left: 18px;
    width: 362px;
    height: h(928);
    background: url("../assets/dynamic/务工人才外框@2x.png") no-repeat;
    background-size: 100% 100%;
    padding: h(20);
  }
}
.main {
  padding: h(25);
  &__left {
    &-title {
      font-size: 20px;
      color: #44ffff;
      line-height: h(40);
      height: h(40);
      margin: 0 auto;

      &.left {
        background: url("../assets/imgs/mt-title.png") no-repeat;
        background-size: 100% 3px;
        background-position: center bottom;
      }
    }

    &-list {
      padding-top: h(31);

      &_item {
        position: relative;
        margin-top: h(17);
      }

      &_item:first-child {
        margin-top: 0;
      }
    }
  }

  .itemImage {
    width: 212px;
    height: h(128);
    overflow: hidden;
    border-radius: 4px;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .shareBar {
    height: h(30);
    background: rgba(255, 255, 255, 0.1);
    margin-top: h(14);
    padding: 0 15px;
  }

  .icon {
    width: 22px;
    height: h(22);
  }

  .index {
    position: absolute;
    width: 52px;
    height: h(36);
    background: url("../assets/imgs/mt-index.png") no-repeat;
    background-size: 100% 100%;
    top: -5px;
    left: 5px;
    padding-left: 18px;
  }

  .index01 {
    position: absolute;
    width: 35px;
    height: h(24);
    background: url("../assets/imgs/wg-index.png") no-repeat;
    background-size: 100% 100%;
    top: 8px;
    left: -3px;
    padding-left: 12px;
  }

  .itemImage01 {
    width: 131px;
    height: h(155);
    overflow: hidden;
    border-radius: 4px;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  &__middle {
    width: 956px;

    &-list {
      &_item {
        width: 440px;
        margin-top: h(20);
      }

      &_item:nth-child(2n) {
        margin-left: 27px;
      }
      &_item:nth-child(1) {
        margin-top: h(14);
      }
      &_item:nth-child(2) {
        margin-top: h(14);
      }
    }
  }

  &__middle-up {
    &-title {
      font-size: 20px;
      color: #44ffff;
      line-height: h(40);
      height: h(40);
      margin: 0 auto;

      &.left {
        background: url("../assets/imgs/lw-title.png") no-repeat;
        background-size: 100% 3px;
        background-position: center bottom;
      }
    }
  }

  &__middle-bottom {
    &-title {
      font-size: 20px;
      color: #44ffff;
      line-height: h(40);
      height: h(40);
      margin: 0 auto;

      &.left {
        background: url("../assets/imgs/lw-title.png") no-repeat;
        background-size: 100% 3px;
        background-position: center bottom;
      }
    }
  }

  &__right {
    &-title {
      font-size: 20px;
      color: #44ffff;
      line-height: h(40);
      height: h(40);
      margin: 0 auto;

      &.left {
        background: url("../assets/imgs/wg-title.png") no-repeat;
        background-size: 100% 3px;
        background-position: center bottom;
      }
    }
  }
}
.code {
  position: absolute;
  width: 242px;
  height: h(130);
  padding: h(25) 20px;
  background: url("../assets/industry/浮窗_底板@2x.png");
  background-size: 100% 100%;
  right: 0;
  bottom: h(26);
  .txt {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    .view {
      width: 24px;
      height: h(17);
      margin-right: 10px;
    }
    .click {
      width: 24px;
      height: h(24);
      margin-right: 10px;
    }
  }
  .icon {
    width: 94px;
    height: h(94);
  }
}
.seamless-warp {
  width: 100%;
  height: h(850);
  overflow: hidden;
}
</style>
